import React from "react";
import { Link } from "gatsby";
import logo from "../../static/img/logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top navbar-farenthesis">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} className="logo" alt="Farenthesis" />
            Farenthesis
          </Link>
          <div id="main-nav" className="collapse navbar-collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/categories" className="nav-link">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
