import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class Footer extends Component {
  render() {
    const { data } = this.props;
    const { edges: categories } = data.allMarkdownRemark;

    return (
      <footer className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <h4 className="text-white">Farenthesis Ventures</h4>
            </div>
            <div className="col-md-2 offset-md-2">
              <strong>Quick Links</strong>
              <ul className="list-unstyled footer-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <strong>Our Services</strong>
              <ul className="list-unstyled footer-links">
                {categories &&
                  categories.map(({ node: category }) => (
                    <li key={category.fields.slug}>
                      <Link to={category.fields.slug}>{category.frontmatter.name}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center text-muted mt-5">
          &copy; 2020 Farenthesis Ventures LLP
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query footerCategoriesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "category" } } }
          sort: { fields: frontmatter___position }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
